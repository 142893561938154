<template>
  <span
    class="price"
  >
    <span
      v-if="prices.fromPrice"
      class="price__from"
    >{{ $t('From (price)') }}</span>

    <i18n-n
      v-if="finalPrice"
      tag="span"
      :value="finalPrice"
      format="currency"
      class="price__final-price"
      :class="{ price__rounded: finalPrice === Math.round(finalPrice), no__currency: !showCurrency }"
      scope="global"
    >
      <template #currency="slotProps">
        <span
          v-if="showCurrency"
          class="price__currency"
        >
          {{ slotProps.currency }}
        </span>
      </template>
      <template #integer="slotProps">
        <span class="price__integer">{{ slotProps.integer }}</span>
      </template>
      <template #decimal="slotProps">
        <span class="price__decimal">{{ slotProps.decimal }}</span>
      </template>
      <template #fraction="slotProps">
        <span class="price__fraction">{{ slotProps.fraction }}</span>
      </template>
    </i18n-n>
    <span
      v-if="showVatTag && locale === 'de'"
      class="price__vat-tag"
    >
      {{ $t('VAT included') }}
    </span>

    <span
      v-if="showDiscount && !prices.fromPrice"
      class="price__rrp"
    >{{ $t('RRP') }}
      <i18n-n
        tag="span"
        :value="regularPrice"
        format="currency"
        class="price__rrp-rice"
        :class="{ price__rounded: regularPrice === Math.round(regularPrice), no__currency: !showCurrency }"
        scope="global"
      >
        <template #currency="slotProps">
          <span
            v-if="showCurrency"
            class="price__currency"
          >
            {{ slotProps.currency }}
          </span>
        </template>
        <template #integer="slotProps">
          <span class="price__integer">{{ slotProps.integer }}</span>
        </template>
        <template #decimal="slotProps">
          <span class="price__decimal">{{ slotProps.decimal }}</span>
        </template>
        <template #fraction="slotProps">
          <span class="price__fraction">{{ slotProps.fraction }}</span>
        </template>
      </i18n-n>
    </span>
  </span>
</template>

<script setup lang="ts">
export interface Props {
  prices: {
    finalPrice?: string | number
    regularPrice: string | number
  }
  showCurrency?: boolean
  showVatTag?: boolean
  hideDiscount?: boolean
}

const { locale } = useI18n()

const props = defineProps<Props>()

const showDiscount = computed(() => {
  return !props.hideDiscount && props.prices.finalPrice && Number(props.prices.regularPrice) > Number(props.prices.finalPrice)
})

const regularPrice = computed(() => {
  return Number(props.prices.regularPrice)
})

const finalPrice = computed(() => {
  return Number(props.prices.finalPrice)
})
</script>

<style src="~layers/app/components/Product/Price.css" />
